.heading1 {
  font-size: 38px;
  line-height: 50px;
  padding-bottom: 40px;
  font-weight: 400;
  text-align: center;
}

.heading2 {
  font-size: 30px;
  line-height: 40px;
  padding-bottom: 20px;
  font-weight: 400;
  text-align: center;
}

.heading3 {
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 15px;
  font-weight: 400;
  text-align: center;
}

.serv-advan {
  padding: 0px 10px;
}

.serv-advan img {
  width: 50px
}

.serv-advan .fc-name {
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  padding-left: 0px;
  padding-right: 0px;
}

/* Leadership inner start */





.top-section {
  margin-top: 80px;
}

/* .about-bd{background-image: url('images/about-bd.png');background-repeat: no-repeat;  background-size: 100%;} */
.breadcrumb {
  padding: 74px 15px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #cbe0e3;
  border-radius: 0px;
}

.breadcrumb a {
  color: #6b6b6b;
}

.breadcrumb a.active {
  color: #4264bf;
  background-color: transparent;
}

.breadcrumb p {
  font-size: 25px;
}

.bd-text {
  padding: 10px 0;
  font-weight: 500;
}

.bd-text a:first-child {
  color: #333;
}

.mtb-0 {
  margin: 0;
}

.leadership-para {
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
}

.teamlist {
  margin-bottom: 50px;
}

.teamlist img {
  height: 250px;
  width: auto;
}

.teamlist .profilebox {
  min-height: 90px;
}

.teamlist .profilebox h4 {
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 0px;
  margin-bottom: 5px;
}

.teamlist .profilebox p {
  font-size: 16px;
}

.profileimg img {
  max-width: 220px;
  width: 100%;
}

.profile {
  padding-top: 60px;
  padding-left: 30px;
}

.profile h4 {
  font-size: 30px;
  line-height: 40px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.profile p {
  font-size: 22px;
}

.leadership-desc p {
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
}

.top-section {
  margin-top: 80px;
}

/* .about-bd{background-image: url('images/about-bd.png');background-repeat: no-repeat;  background-size: 100%;} */
.breadcrumb {
  padding: 74px 15px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #cbe0e3;
  border-radius: 0px;
}

.breadcrumb a {
  color: #6b6b6b;
}

.breadcrumb a.active {
  color: #4264bf;
  background-color: transparent;
}

.breadcrumb p {
  font-size: 25px;
}

.bd-text {
  padding: 10px 0;
  font-weight: 500;
}

.bd-text a:first-child {
  color: #333;
}

.mtb-0 {
  margin: 0;
}

.leadership-para {
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
}

.teamlist {
  margin-bottom: 50px;
}

.spacetop {
  margin-top: 110px;
}

.leadership-desc {
  font-size: 17px;
  line-height: 22px;
}

.lead-desc-sec {
  margin-top: 50px;
}


@media screen and (max-width: 650px) {
  .spacetop {
    margin-top: 20px;
  }

  .lead-desc-sec {
    margin-top: 20px;
  }

}


.leadership-para {
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
}

.teamlist {
  margin-bottom: 50px;
}

.spacetop {
  margin-top: 110px;
}

.leadership-desc {
  font-size: 17px;
  line-height: 22px;
}

.lead-desc-sec {
  margin-top: 50px;
}


@media screen and (max-width: 650px) {
  .spacetop {
    margin-top: 20px;
  }

  .lead-desc-sec {
    margin-top: 20px;
  }

}

/* Leadership inner end */


/* //google */
.mapControls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* #searchMapInput {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 50%;
} */
#searchMapInput:focus {
  border-color: #4d90fe;
}


input[type="date"]::before {
  color: #999;
  content: attr(placeholder) ": ";
}

input[type="date"]:focus::before {
  content: "" !important;
}

.distance-cir .distance-text span {
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
}

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sign-popup .form-control {
  height: 45px;
}

.sign-popup .modal-header img {
  float: right;
  width: 135px;
}

.sign-popup .modal-title {
  font-size: 40px;
  padding-top: 25px;
  font-weight: 600;
}

.sign-popup .close {
  float: right;
  font-size: 50px;
  font-weight: 300;
  line-height: 15px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.sign-popup .close:focus {
  outline: none !important;
}

.sign-popup .modal-footer {
  padding: 25px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  font-size: 19px;
  font-weight: 600;
}

.forgotP {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
}

.img-center img {
  float: none !important;
}

.lsucess {
  font-size: 22px;
  line-height: 30px;
}

/* Header login profile dropdown */
.agddlmenu ul li {
  padding: 0px 0 !important;
  margin-left: 0px !important;
  float: none !important;
}

.agddlmenu .dropdown-menu>li>a {
  padding: 7px 20px;
}

.agddlmenu ul {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  ;
}

.agddlmenu a {
  cursor: pointer;
}

/* rating */
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked)>label:before {
  content: '★ ';
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}


/* /// */
.ratestar {
  float: left;
  height: 46px;
  padding: 0 10px;
}

.ratestar:not(:checked)>input {
  position: fixed;
  top: -9999px;
}

.ratestar:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.ratestar:not(:checked)>label:before {
  content: '★ ';
}

.ratestar>input:checked~label {
  color: #ffc700;
}

.ratestar:not(:checked)>label:hover,
.ratestar:not(:checked)>label:hover~label {
  color: #deb217;
}

.ratestar>input:checked+label:hover,
.ratestar>input:checked+label:hover~label,
.ratestar>input:checked~label:hover,
.ratestar>input:checked~label:hover~label,
.ratestar>label:hover~input:checked~label {
  color: #c59b08;
}



.labl>input {
  /* HIDE RADIO */
  visibility: hidden;
  /* Makes input not-clickable */
  position: absolute;
  /* Remove input from document flow */
}

.labl>input+div {
  /* DIV STYLES */
  cursor: pointer;

}

.labl>input:checked+div {
  /* (RADIO CHECKED) DIV STYLES */

  border: 1px solid #000;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  line-height: 20px;
  word-wrap: break-word;
  font-family: 'Barlow', sans-serif;
}

h3 {
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 18px;
}

p {
  line-height: 22px;
  font-size: 15px;
}

a {
  color: #4265bf;
  text-decoration: none !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.inline-block {
  display: inline-block;
}

.mr-10 {
  margin-right: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.service-list {
  margin-top: 60px !important;
}

.service-list ul li {
  float: left;
  list-style-type: none;
  width: 20%;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.service-list ul {
  margin-top: 0;
  margin-bottom: 10px;
  margin: 0;
  padding: 0;
}

.service-list ul li img {
  width: 90px;
}

.service-list ul li p {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}

.warehouse-form ul {
  margin: 0;
  padding: 0;
}

.warehouse-form ul li {
  list-style-type: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 6px;
}

.warehouse-form ul li:last-child {
  list-style-type: none;
  border-bottom: 0;
}

.fw-house {
  padding: 7px 10px;
}

.warehouse-form {
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
}

.warehouse-form span {
  color: #9E9E9E;
  font-size: 13px;
}

.warehouse-form ul li p {
  margin-bottom: 5px;
}

.why-choose-us {
  text-align: center;
  padding: 40px 0;
}

.listpadding {
  padding-top: 25px;
}

.wc-list img {
  width: 60px;
}

.about-img img {
  width: 260px;
  max-width: 100%;
}

.ab-icon img {
  width: 55px;
}

.bd-right {
  border-right: 1px solid #ddd;
}

.faqs-img img {
  width: 350px;
  max-width: 100%;
}

.faqs-img {
  margin-top: 90px;
}

.input-group-btn {
  width: 0px;
}

#mySidenav {
  position: fixed;
  left: 0px;
  top: 88%;
  transition: 0.3s;
  padding: 5px;
  width: 100px;
  z-index: 1;
  text-decoration: none;
  font-size: 15px;
  border-right: 8px solid #4676e2;
  border-radius: 0 5px 5px 0;
  background-color: #d52027;
}

#mySidenav a {
  color: white;
}

#mySidenav a:hover {
  left: 0;
}

@media screen and (max-width: 768px) {
  .service-list ul li {
    width: 100%;
  }

  .text-xs-center {
    text-align: center;
  }

}


/* header */
.navbar-default {
  background-color: #ffffff;
}

.navbar {
  border-radius: 0;
}

.navbar {
  position: relative;
  min-height: 80px;
  margin-bottom: 0px;
}

.navbar-brand>img {
  display: inline-block;
}

.navbar-brand {
  float: left;
  height: 80px;
  padding: 8px 0px;
}

.navbar-right {
  padding: 23px 0;
  margin-bottom: 0;
}

.top-navbar>li>a {
  position: relative;
  display: block;
}

#custom-nav {
  box-shadow: 0 5px 5px -6px #999;
  z-index: 999;
}

.top-navbar li {
  float: left;
  list-style-type: none;
  margin-right: 15px;
}

.menu .c-care {
  font-weight: 500;
  color: black;
}

.nav-links {
  display: inline-block;
  padding: 30px 10px;
  margin-left: 30px;
}

.top-navbar {
  float: right;
}

.nav-links .verline {
  padding: 0px 10px;
  color: #4265bf;
}

.huser {
  width: 30px;
}



/* Banner */
.banner {
  background-image: url('../images/home-banner.jpg');
  height: 430px;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}

.banner .slider-head {
  margin-top: 8%;
  margin-left: 15px;
}

.banner .slider-head .qline1 {
  font-size: 35px;
  font-weight: 300;
  color: #000000;
  padding-bottom: 5px;
}

.banner .slider-head .qline2 {
  font-size: 25px;
  font-weight: 400;
  color: #000000;
  display: inline-block;
  padding: 10px 0;
  border-top: 1px solid #4676e2;
}

.top-section {
  margin-top: 80px;
}

#whstates.top-section {
  margin-top: 0px;
  padding-top: 80px;
}

/* Home Page */
.value-services {
  text-align: center;
  padding: 30px 0;
}

.find-banner {
  position: relative;
}

.find-banner .imgboxh200 {
  height: 200px;
  overflow: hidden;
}

.find-banner .imgboxh400 {
  height: 400px;
  overflow: hidden;
}

.find-banner img {
  width: 100%;
  height: auto;
}

.find-warehouse-box {
  border: 1px solid #ddd;
  overflow: hidden;
  border-radius: 4px;
}

.find-content {
  padding: 20px;
}

.find-content .heading {
  margin-top: 0px;
}

.find-content p {
  font-size: 15px;
}

.more-link {
  color: red;
  font-size: 14px;
}

.about-list-img {
  float: left;
}

.about-list-text {
  padding-left: 50px;
  border-bottom: 1px solid #ddd;
}

.about-img {
  padding: 90px 0;
}

.testimonial {
  background: #f4f4f4;
  padding: 30px 0;
}

/* About Page */
.about-bd {
  background-image: url('../images/bd.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.breadcrumb {
  padding: 74px 15px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #cbe0e3;
  border-radius: 0px;
}

.breadcrumb a {
  color: #6b6b6b;
}

.breadcrumb a.active {
  color: #4264bf;
  background-color: transparent;
}

.breadcrumb a.active::after {
  content: "";
}

.breadcrumb p {
  font-size: 25px;
}

.about-banner {
  background-image: url('../images/aboutbnr.png');
}

.banner-style {
  height: 440px;
  background-repeat: no-repeat;
  background-position: top right;
}

.aboutText {
  padding-top: 4%;
  margin-bottom: 0px;
}

.aboutText h3 {
  padding-bottom: 0px;
}

.aboutText p {
  margin-top: 20px;
}

.aboutwChoose {
  color: #fff;
  background: #4265c0;
  margin-top: 55px;
  padding: 70px 0px;
}

.info-clist {
  float: left;
}

.info-clist img {
  width: 55px;
  vertical-align: baseline;
}

.info-clist-dtl {
  padding-left: 15px;
  padding-top: 6px;
}

.info-clist-dtl h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

.info-clist-dtl p {
  font-size: 22px;
  font-weight: 400;
}

.yc-heading {
  font-size: 23px;
  font-weight: 300;
  margin-bottom: 25px;
}

.ptop-50 {
  padding-top: 50px;
}

.our-team-list {
  padding-top: 50px;
  text-align: center;
}

.bd-text {
  padding: 10px 0;
  font-weight: 500;
}

.bd-text a:first-child {
  color: #333;
}

.aboutbnr-img img {
  max-width: 400px;
  width: 100%;
}

.aboutbnr-img {
  margin-top: 50px;
}

.abt-heading {
  font-size: 15px;
  font-weight: 400;
  min-height: 50px;
}

.abt-dtl {
  border-bottom: 1px solid #ddd;
}

.abt-dtl h4 {
  font-size: 22px;
}

.mt-60 {
  margin-top: 60px;
}

.caption {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.caption p {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.caption-icon img {
  width: 65px;
}

.caption-icon {
  margin-top: -35px;
}

.storage-list img {
  width: 60px !important;
}

.facilitates {
  text-align: center;
  display: inline-block;
  padding-bottom: 20px;
}

.facilitates ul {
  list-style-type: none;
  padding-left: 0px;
}

.facilitates .facilitates-list {
  float: left;
  padding: 0px 30px;
}

.facilitates .facilitates-list img {
  width: 50px;
}

.facilitates .facilitates-list .fc-name {
  font-size: 15px;
  margin-top: 10px;
}

.careers-img img {
  max-width: 100%;
  width: 330px;
}

.car-div {
  background: #ddd;
  width: 100%;
  height: 1px;
  margin: 60px 0;
}


.mtb-0 {
  margin: 0;
}

/* contact Page */
.contact-bd {
  background-image: url('../images/contact-bd.png');
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 68px 15px;
}

.contactaddress .location {
  background-image: url(../images/location.png);
  background-repeat: no-repeat;
  padding-left: 50px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.contactaddress .phone {
  background-image: url(../images/c-call.png);
  background-repeat: no-repeat;
  padding-left: 50px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 40px;
}

.contactaddress .mail {
  background-image: url(../images/c-mail.png);
  background-repeat: no-repeat;
  padding-left: 50px;
  padding-top: 2px;
  padding-bottom: 10px;
  margin-top: 40px;
}

.getintouch {
  margin-bottom: 55px;
}

.contact-form .form-control.ag-fc {
  height: 40px;
  border-radius: 0;
}

.contact-form .form-control {
  border-radius: 0;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

/* careers Page */
.careers-banner {
  background-image: url('../images/careers-bnr.png');
}

.carbanner-style {
  height: 440px;
  background-repeat: no-repeat;
  background-position: top left;
}

.careersbluebg {
  color: #fff;
  background: #4265c0;
  margin-top: 55px;
  padding: 50px 0px;
}

.careersbluebg .car-title h2 {
  margin-top: 0;
  font-weight: 400;
}

/* Position Vacant */
.ag-vacant {
  border: 1px solid #ddd;
  border-radius: 11px;
  padding: 15px;
}

.ag-vacant-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.btn-jobtype {
  background: #4265c0;
  color: #ffffff;
  border-radius: 20px;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  float: right;
}

.btn-jobtype:hover,
.btn-jobtype:focus {
  color: #ffffff;
}

.applybtn {
  font-weight: 500;
  font-size: 13px;
}


/* ============== */
.th-margin-remove {
  margin: 0 !important
}

figure.testimonial {
  position: relative;
  float: left;
  margin-top: 10%;
  margin-bottom: 10px;
  padding: 0 20px;
  text-align: left;
  box-shadow: none !important;
}

figure.testimonial * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

figure.testimonial blockquote {
  background-color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0;
  padding: 20px 30px 20px;
  position: relative;
  border-left: 0;
}

.red-quote {
  position: absolute;
  left: 0px;
  background: #d41c24;
  color: white;
  font-size: 30px;
  padding: 15px 4px;
  height: 65px;
}

figure.testimonial blockquote:before {
  top: 25px;
  left: 20px;
}

figure.testimonial .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fff;
  margin: 0;
  position: absolute;
}

figure.testimonial .author {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  margin-top: 15px;
}

figure.testimonial .author h5 {
  opacity: 0.9;
  margin: 0;
}

figure.testimonial .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
  outline: none;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome";
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -40px;
}

.slick-prev:before {
  content: "";
}

.slick-next {
  right: -40px;
}

.slick-next:before {
  content: "";
}

/* =================== */
.accordion {
  border-top: 1px solid #ddd !important;
  color: #4265c0;
  cursor: pointer;
  padding: 18px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: all 0.5s;
  font-size: 15px;
}

.accordion::after {
  content: "\02795";
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.accordion.active::after {
  content: "\2796";
}

.panel {
  margin-bottom: 0;
  padding: 0 10px;
  background-color: white;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
}


/* ============== */
.banner-serch-form .form-control {
  height: 45px;
}

.wrapper {
  margin: 8px 0;
}

.banner-serch-form .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 65%;
}

.banner-serch-form .serchbtn {
  float: left;
  border-radius: 0;
  margin: 0px -1px;
  height: 45px;
  padding: 10px 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Agrigate-Inner Page */
.inner-form {
  padding-top: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #e5e5e5;
}

.filter-box {
  background: #cbe0e3;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  font-size: 17px;
  font-weight: 600;
  background-image: linear-gradient(white, #ecf4ff);
}

.warehouse-box {
  background: #cbe0e3;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 5px 20px;
  background-image: linear-gradient(white, #ecf4ff);
}

.ag-breadcrumb {
  padding-left: 0px;
  margin-bottom: 0;
}

.ag-breadcrumb>li {
  display: inline-block;
}

.ag-breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.warehouse-box p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.warehouse-box .ag-breadcrumb li a {
  color: #333;
}

.inner-box {
  background: white;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  padding: 20px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
}

.right-heading {
  color: #4265bf;
  font-size: 20x;
  margin-top: 0;
  margin-bottom: 10px;
}

.filter-panel {
  padding: 10px 15px;
  border-bottom: 2px dashed #9E9E9E;
}

.inner-box .filter-panel:last-child {
  border-bottom: 0
}

.rent-box {
  background: white;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.rentbox-title h4 {
  margin: 0;
  padding-top: 5px;
  font-size: 18px;
}

.rentbox-title {
  padding: 10px 20px;
}

.text-center {
  text-align: center;
}

.rent-img {
  position: relative;
  float: left;
}

.img-label-view {
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
  background: black;
  padding: 0px 12px;
}

.img-label {
  position: absolute;
  bottom: 0;
  background: #4265c0;
  color: white;
  padding: 6px 15px;
}

.width30 {
  width: 32%;
}

.warehouse-form .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  padding-left: 0;
  height: 25px;
}

.warehouse-form .fgroup {
  margin-bottom: 9px;
}

.rent-dtl-form {
  padding: 20px;
  min-height: 350px;
}

.rent-img img {
  width: 250px;
  height: 250px;
  min-width: 100%;
}

.rentbox-title .location {
  text-decoration: underline;
  color: #9E9E9E;
}

.location-dtl {
  margin-bottom: 0;
  font-size: 14px;
  padding-top: 3px;
}

.rent-add {
  font-weight: 500;
}

.rentb-bottom {
  border-bottom: 1px dashed #9E9E9E;
}

.sortby {
  float: right;
}

.activity-head img {
  width: 30px;
}

.activity-head span {
  font-weight: 600;
}

.warehouse-detail-box {
  padding: 0px 20px;
  min-height: 245px;
}

.warehouse-detail-box .whbox {
  height: 212px;
}

.warehouse-detail-box .bb-line {
  border-bottom: 1px solid #ddd;
}

.warehouse-detail-box .fgroup {
  margin-top: 4px;
}

.warehouse-detail-box .fgroup p {
  color: black;
  font-weight: 500;
  margin-bottom: 6px;
}

.whbtn1 {
  color: #000000;
  font-size: 16px;
  padding: 3px 15px 5px 15px;
  line-height: 18px;
  margin-bottom: 0px;
}

.nearby {
  display: inline-block;
  width: 200px;
}

.nearby .mr-10 {
  margin-right: 5px;
}

.loadmore {
  text-align: center;
}

.loadmore a {
  display: inline-block;
  width: auto;
}

/* Our Partner tab */
.tab-container ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.tab-container ul.tabs li.current {
  background: #4265c0;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-container ul.tabs li {
  background: none;
  color: #5f5f5f;
  display: inline-block;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.tab-container .tab-content.current {
  display: block;
  overflow: hidden;
}

.tab-container .tab-content {
  display: none;
  background: #ededed;
  padding: 15px;
  border-top: 1px solid #4265c0;
  min-height: 170px;
}

/* Warehouse Details tab 2*/
.tab-container ul.ag-tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-bottom: 1px solid #4265c0;
}

.tab-container ul.ag-tabs li.current {
  background: #4265c0;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-container ul.ag-tabs li {
  background: none;
  color: #5f5f5f;
  display: inline-block;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.tab-container .ag-tab-content.current {
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
}

.tab-container .ag-tab-content {
  display: none;
  padding: 15px;
}

/* Top Cities*/
.tab-container ul.city-tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.tab-container ul.city-tabs li.current {
  background: #4265c0;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-container ul.city-tabs li {
  background: none;
  color: #5f5f5f;
  display: inline-block;
  padding: 10px 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.tab-container .city-tab-content.current {
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
}

.tab-container .city-tab-content {
  display: none;
  background: #ededed;
  padding: 15px;
  border-top: 1px solid #4265c0;
}


/* Footer */
footer .top-footer {
  margin-top: 80px;
  background: #ededed;
  width: 100%;
  border-top: 3px solid #9E9E9E;
  padding-top: 20px;
  padding-bottom: 70px;
}

.f-padd {
  padding: 50px 0;
}

.contact-dtl .con-address {
  font-size: 14px;
}

.contact-dtl .con-address img {
  width: 20px;
}

.copyright {
  padding: 15px 0;
  color: #fff;
  background: #4265c0;
}

.city-name {
  font-size: 14px;
  margin-bottom: 20px;
}

.city-name span {
  cursor: pointer;
}

.footer-nav {
  text-align: center;
  border-top: 1px solid #9E9E9E;
  border-bottom: 1px solid #9E9E9E;
  padding: 15px 0;
}

.footer-nav a {
  color: black;
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 600;
}

.social-icon-list {
  margin-left: 40px;
}

.distance-cir {
  border: 1px solid #999;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.distance-cir img {
  margin-top: 10px;
}

.distance-cir .distance-text {
  line-height: 14px;
  margin-top: 3px;
  font-size: 12px;
}

.distance-cir .distance-text span {
  padding-top: 3px;
}

.custom-logo {
  width: 180px;
}

.play-store {
  width: 111px;
}

.social-icon-list img {
  width: 30px;
}

.con-address .mail-left {
  margin-left: 40px;
}

.con-address .adrspng {
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

.rite {
  color: #ffffff;
}

.rite:hover {
  color: #f5f5f5;
}

.box {
  cursor: pointer;
}

.box:hover .tooltip--multiline {
  display: block;
}

.tooltip--multiline {
  background: #ddd;
  color: #000;
  display: none;
  padding: 10px;
  position: absolute;
  bottom: -100%;
  width: 150px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.tooltip--multiline:before {
  border-bottom: 10px solid #ddd;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: '';
  position: absolute;
  top: -10%;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.wd-table {
  border-top: 1px dashed #9E9E9E;
}

.wd-table .table>tbody>tr>td,
.wd-table .table>tbody>tr>th,
.wd-table .table>tfoot>tr>td,
.wd-table .table>tfoot>tr>th,
.wd-table .table>thead>tr>td,
.wd-table .table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: transparent;
}

.amenities-cir {
  border: 1px solid #999;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  float: left;
  line-height: 45px;
}

.amenities-text {
  padding-left: 60px;
  padding-top: 4px;
  font-weight: 600;
  font-size: 16px;
}

.amenities-subtext {
  font-size: 12px;
  color: #999;
}

.sw-img img {
  width: 120px;
  height: 120px;
  min-width: 100%;
  border-radius: 4px;
}

.similar-warehouse-list {
  position: relative;
  padding: 0px 15px;
  display: inline-block;
}

.similar-warehouse-list .img-label {
  position: absolute;
  bottom: 0;
  background: #4265c0;
  color: white;
  padding: 6px 15px;
  font-size: 10px;
}

.sw-img {
  float: left;
}

.sw-title {
  font-weight: 600;
  color: black;
}

.sw-content {
  margin-left: 15px;
}

.sw-heading {
  padding: 10px 15px;
}

.sw-divider {
  padding: 10px 15px;
  border-bottom: 1px dashed #9E9E9E;
}

.surveillance-tab {
  padding: 90px 0px;
}

.surveillance-tab img {
  width: 200px;
}

.full-heading {
  background: #4676e2;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}

.sd-table .table>tbody>tr>td,
.sd-table .table>tbody>tr>th,
.sd-table .table>tfoot>tr>td,
.sd-table .table>tfoot>tr>th,
.sd-table .table>thead>tr>td,
.sd-table .table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: transparent;
}

.btn-primary {
  color: #fff;
  background-color: #4676e2;
  border-color: #2e6da4;
}

.btn-danger {
  color: #fff;
  background-color: #d41c24;
  border-color: #d43f3a;
}

.feedback-img {
  float: left;
}

.feedback-img img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.feedback-content {
  padding-left: 70px;
}

.feedback-content p {
  margin-bottom: 0
}

.feedback-content i {
  color: #4676e2;
}

.feedback {
  padding: 20px;
}

.filter-box .info-ui {
  padding-left: 0;
  margin-bottom: 0;
  padding-bottom: 18px;
  padding-top: 0px;
}

.filter-box .info-ui li {
  float: left;
  list-style-type: none;
  font-weight: 600;
  margin-right: 20px;
}

.filter-box .info-ui li .dtl-li {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.wr-dtl {
  margin-bottom: 10px;
}

/* Sign In */
.sign-popup .signin.checkbox {
  font-size: 15px;
  margin: 0;
  float: left;
}

.forgotP {
  text-align: right;
  font-size: 15px;
}

.sign-popup .modal-header {
  background: #f1f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.sign-popup .modal-footer {
  background: #f2f2f2;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.uprofile-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid white;
}

.uprofile-img {
  background: #f0f7ff;
  padding: 30px;
}

.uprofile-form {
  padding: 40px 30px;
}

.filter-panel .active {
  color: #d41c24;
}

.top-heading {
  font-size: 20px;
  font-weight: 600;
}

.heading a {
  color: #333;
}

.filter-panel a {
  color: #333;
}

.pile-img img {
  width: 100%;
  height: 110px;
  border-radius: 4px;
}

.pile-dtl {
  font-size: 14px;
  line-height: 20px
}

.inward-link {
  float: right;
  color: #4CAF50;
  text-transform: uppercase;
}

.outward-link {
  float: right;
  color: #e14545;
  text-transform: uppercase;
}

.pile-dtl b {
  font-weight: 500;
  color: black;
}

/* step form */
.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.floor-radio {
  border: 1px solid #ddd;
  padding: 5px 20px 15px;
  border-radius: 4px;
}

.file-choose label {
  color: blue;
  cursor: pointer;
}

.file-choose label:hover {
  text-decoration: underline;
}

#file_input_id {
  display: none;
}

.BDinner-box {
  background: white;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  padding: 7px;
}

.BD-img img {
  width: 120px;
  height: 90px;
  min-width: 100%;
}

.BD-img {
  float: left;
}

.BD-content {
  margin-left: 10px;
}

#calendar-container {
  color: #333;
  display: block;
  margin: 2vh auto;
  border-radius: 10px;
  padding: 5px 10px;
}

#calendar-title {
  width: 100%;
  margin: 5vh auto;
  text-align: center;
  font-size: 1.5em;
}

#calendar-table {
  width: auto;
  margin: auto;
}

#calendar-table td,
#calendar-table th {
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-radius: 20px;
}

.today-cell {
  background-color: #ccc;
}

#date-text {
  text-align: center;
}

.cbtn {
  display: inline-block;
  width: 0px;
  height: 0px;
  border: 10px solid transparent;
  margin: 1px 5px;
}

.cbtn.left {
  border-right: 10px solid black;
}

.cbtn.right {
  border-left: 10px solid black;
}

.cbtn.left:hover {
  border-right: 10px solid #444;
  cursor: pointer;
}

.cbtn.right:hover {
  border-left: 10px solid #444;
  cursor: pointer;
}

#calendar-table td:hover {
  background-color: #bbb;
  cursor: pointer;
  color: black;
}

/* ========================= */

.vap {
  padding: 0px 25px;
}

.nav-left {
  float: left;
}

.nav-right {
  float: right;
}

.menu.nav-left li {
  padding: 30px 0px;
  margin-left: 50px;
}

.menu.nav-right li {
  padding: 23px 0px;
  margin-left: 20px;
}

/* header */
.header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  text-decoration: none;
}

.verline {
  padding: 0px 10px;
  color: #4265bf;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 0px;
  text-decoration: none;
}


/* menu */
.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  float: right;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked~.menu {
  max-height: 240px;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}


.location-box-cover {
  display: inline-block;
  vertical-align: top;
  float: left;
  width: 60%;
  height: 45px;
  overflow: hidden;
}

.location-box-cover .location-box {
  display: block;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.location-box-cover .location-box:focus {
  border: 1px solid #ccc;
  outline: none;
}

.google-covert {
  background-color: #ffffff;
}

.banner-serch-form .form-control {
  display: inline-block;
  vertical-align: top;
  float: left;
  width: 40%;
}

.banner-serch-form .form-control.googlelocation {
  width: 60%;
}

.partnerbox {
  display: inline-block;
  width: 150px;
  vertical-align: top;
  margin: 10px;
  text-align: center;
}

.partnerbox .imgbox {
  width: 80px;
  height: 90px;
  display: inline-block;
}

.partnerbox .imgbox img {
  width: 100%;
}

.partnerbox .name {
  display: block;
}

.swhcontainer {
  padding: 10px;
}

.swhcontainer .similar-warehouse-list {
  padding: 0px;
}

/* ================================== */








/*  -------******** big device ********---------- */
@media screen and (min-width:1350px) and (max-width:1920px) {}

@media screen and (min-width:1025px) and (max-width:1300px) {}

/*  ----------******** End of laptop device ********-------------- */
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .sw-img img {
    width: 100%;
    height: 120px;
    min-width: 100%;
    border-radius: 4px;
  }

  .sw-content {
    margin-left: 0px;
  }

  .similar-warehouse-list .img-label {
    position: absolute;
    bottom: 110px;
    background: #4265c0;
    color: white;
    padding: 6px 15px;
    font-size: 10px;
  }

  .tab-container ul.ag-tabs li {
    background: none;
    color: #5f5f5f;
    display: inline-block;
    padding: 10px 25px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
  }

  .rent-img img {
    width: 195px;
    height: 250px;
    min-width: 100%;
  }

  .social-icon-list {
    margin-left: 30px;
  }

  .tab-container ul.city-tabs li {
    padding: 10px 30px;
  }
}

@media screen and (min-width: 651px) and (max-width: 900px) {
  .distance-cir {
    margin-bottom: 15px;
  }

  .rent-img img {
    width: 240px;
    height: 250px;
    min-width: 100%;
  }

  .inner-box {
    margin-bottom: 20px;
  }

  .find-warehouse-box {
    margin-bottom: 20px;
  }

  .slick-arrow {
    display: none !important;
  }

  .banner {
    height: 250px;
  }

  .about-img {
    padding: 20px 0;
  }

  .about-banner {
    background: white;
  }

  .banner-style {
    height: unset;
  }

  .con-address span br {
    display: none;
  }

  .careers-banner {
    background: white;
  }

  .carbanner-style {
    height: unset;
  }

  .about-bd {
    background-image: url(/static/media/bd.9cd83e19.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .serv-advan {
    padding: 0px 10px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .modal-dialog.dialog-sign {
    width: 500px;
    margin: 30px auto;
  }
}

@media (min-width: 767px) {
  .header li {
    float: left;
  }

  .header .menu {
    clear: none;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

/*  ----------***** Mobile device *****--------- */
@media screen and (max-width: 650px) {
  .navbar-brand {
    float: none;
  }

  .navbar-header {
    text-align: center !important;
  }

  .nav-links {
    display: block !important;
    padding: 10px !important;
    margin-left: 0 !important;
    text-align: center !important;
  }

  .navbar-right {
    padding: 0px 0;
    margin-bottom: 0;
  }

  .top-navbar {
    float: right;
    margin-left: 25px;
  }

  .top-navbar li {
    margin-bottom: 10px;
  }

  .top-section {
    margin-top: 60px;
  }

  .banner {
    height: 430px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-img {
    padding: 20px 0;
  }

  .filter-box .info-ui li {
    float: none;
    line-height: 23px;
  }

  .filter-box .info-ui {
    padding-bottom: 0px;
  }

  .tab-container ul.ag-tabs li {
    display: block;
    text-align: center;
  }

  .distance-cir {
    margin-bottom: 15px;
  }

  .wd-table.table-responsive {
    border: none;
  }

  .sw-img img {
    width: 100%;
    height: auto;
  }

  .sw-img {
    float: left;
    position: relative;
  }

  .sw-content {
    margin-left: 0;
  }

  .tab-container ul.tabs li {
    display: block;
    text-align: left;
    padding: 10px 10px;
  }

  .social-icon-list {
    margin-left: 0px;
    display: block;
    margin-top: 15px;
  }

  .inner-box {
    margin-bottom: 20px;
  }

  .sortby {
    float: none;
  }

  .rent-img img {
    width: 100%;
    height: auto;
    min-width: 100%;
  }

  .width30 {
    width: 100%;
  }

  .warehouse-form {
    margin-left: 0px;
    margin-top: 15px;
  }

  .menu.nav-left li {
    padding-top: 10px;
    margin-left: 0px;
    padding-bottom: 10px;
  }

  .nav-right {
    float: none;
  }

  .menu.nav-right li {
    padding-top: 0px;
    margin-left: 0px;
    padding-bottom: 10px;
    display: block;
  }

  .header .menu {
    text-align: center;
  }

  .nav-left {
    float: none;
  }

  .stepwizard-step {
    display: block;
    text-align: center;
    position: relative;
  }

  .banner .slider-head .qline1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
  }

  .banner .slider-head .qline2 {
    font-size: 16px;
  }

  .banner-serch-form .input-group {
    position: relative;
    display: block;
    width: 100%;
  }

  .banner-serch-form .form-control {
    height: 45px;
    margin-bottom: 15px;
  }

  .banner-serch-form .serchbtn {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .find-warehouse-box {
    margin-bottom: 20px;
  }

  .slick-arrow {
    display: none !important;
  }

  .tab-container ul.city-tabs li {
    display: block;
    text-align: left;
    padding: 10px 10px;
  }

  #mySidenav.sidenav {
    display: none;
  }

  .about-banner {
    background: white;
  }

  .xs-center {
    text-align: center;
  }

  .info-clist {
    float: none;
  }

  .info-clist-dtl {
    padding-left: 10px;
    padding-top: 0px;
    text-align: left;
  }

  .yc-heading {
    margin-bottom: 15px;
  }

  .con-address span br {
    display: none;
  }

  .careers-banner {
    background: white;
  }

  .about-bd {
    background-image: url(/static/media/bd.9cd83e19.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .facilitates .facilitates-list {
    float: none;
    padding: 0px 30px;
    margin-bottom: 20px;
  }

  .serv-advan {
    padding: 0px 10px;
    margin-bottom: 30px;
  }

  .profile {
    padding-top: 30px;
    padding-left: 0px;
  }

  .slick-slide {
    width: 100% !important;
  }

  .footer-nav a {
    display: inline-block;
    width: 120px;
    padding: 0px;
  }

  .social-icon {
    margin-top: 30px;
  }

  footer {
    text-align: center;
  }

  footer .top-footer {
    padding-bottom: 0px;
  }

  .copyright {
    text-align: center;
  }

  .copyright .pull-right {
    float: none !important;
  }

  footer .play-store.mr-10 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .con-address .adrspng {
    float: none;
    vertical-align: bottom;
    width: 16px !important;
  }

  .contact-dtl .con-address {
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 20px;
    line-height: 20px;
  }

  .search-container.mt-35 {
    margin-top: 0px;
  }

  .banner-serch-form .form-control.googlelocation {
    width: 100%;
  }

  .banner-serch-form .form-control {
    width: 100%;
  }

  .find-warehouse h2 {
    margin-bottom: 30px;
  }

  .fw-house {
    padding: 0px;
    padding-top: 10px;
  }

  .warehouse-form {
    margin-top: 0px;
  }

  .city-tab-content .col-md-2 {
    display: inline-block;
    width: 140px;
  }

  .aboutText h3 {
    font-size: 24px;
  }

  .teamlist {
    text-align: center;
  }

  .teamlist .profilebox {
    min-height: unset;
  }
}

@media screen and (min-width: 451px) and (max-width: 650px) {}

@media screen and (max-width: 450px) {}

/*  ---------***** End of Mobile device *****--------*/